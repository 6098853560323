/* Logoer */
import LinkedinLogo from '../pictures/LinkedinLogo.png' //https://brand.linkedin.com/downloads
import GithubLogo from '../pictures/GithubLogo.png' //https://github.com/logos

/* Css */
import '../css/Footer.css';
import { NavLink } from 'react-router-dom';

export default function Footer() {
    return(
        <div className='footer'>
            <div className='footerColums'>
                <p className='footerTitle'>Socials</p>
                <a href='https://www.linkedin.com/in/hallvard-dahle-81a3841b9/'><img src={LinkedinLogo} className='logo' alt='LinkedIn Logo' /></a>
                <a href='https://github.com/hallihk'><img src={GithubLogo} className='logo' alt='Github Logo' /></a>
            </div>
            <div className='footerColums'>
                <p className='footerTitle'>Pages</p>
                    <div className='footerNav'>
                    <NavLink
                    to="/"
                    >
                    <p>Home</p>
                    </NavLink>
                    <NavLink
                        to="projects"
                    >
                    <p>Projects</p>
                    </NavLink>
                    <NavLink
                        to="about"
                    >
                    <p>About</p>
                    </NavLink>
                    <NavLink
                        to="contact"
                    >
                    <p>Contact</p>
                    </NavLink>
                </div>
            </div>
            <div className='footerColums'>
                <p className='footerTitle'>Contact</p>
                <p>hallvard@kleppnett.no</p>
                <p>+47 95764012</p>
            </div>
        </div>
    )
}