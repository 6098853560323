
import '../css/Contact.css';

export default function Contact() {
    return(
        <div className='contact'>
            <p className='title'>Reach me</p>
            <p>Phonenummer: +47 95764012</p>
            <p>Linkedin: <a href='https://www.linkedin.com/in/hallvard-dahle-81a3841b9/'>HallvardDahle</a></p>
            <p>Email: Hallvard@kleppnett.no</p>
        </div>
    )
}