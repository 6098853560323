import { NavLink } from 'react-router-dom';

export default function Nav() {
    return(
        <div className='nav'>
            <p className='mainTitle'>Hallvard Dahle</p>
            <NavLink
                to="/"
                activeclassname="active"
                className="notActive"
            >
            <p className='navTitle'>Home</p>
            </NavLink>
            <NavLink
                to="about"
                activeclassname="active"
                className="notActive"
            >
            <p className='navTitle'>About</p>
            </NavLink>
            <NavLink
                to="contact"
                activeclassname="active"
                className="notActive"
            >
            <p className='navTitle'>Contact</p>
            </NavLink>
            <NavLink
                to="projects"
                activeclassname="active"
                className="notActive"
            >
            <p className='navTitle'>Projects</p>
            </NavLink>
        </div>
    )
}