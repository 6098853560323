/* bilde */
import meg from '../pictures/selvPotrett.jpg'
import About from './About'
import Contact from './Contact'
import Projects from './Projects'

export default function Home() {
    return(
        <>
            <div className='content-wrapper'>
                <div className='text-content'>
                    <p className='myName'>I'm Hallvard E. Dahle</p>
                    <p className='infoText'>and i want to work with frontend development.</p>
                </div>
                <div className='image-content'>
                    <img src={meg} className='image' alt='Bilde av Hallvard Dahle' />
                    <p className='pictureText'>By: Aleksander Klein Carlsen</p>
                </div>
            </div>
            <About />
            <Contact />
            <Projects />
        </>
    )
}