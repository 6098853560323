
import '../css/About.css';

const karakter = [
    {
        fag: 'Grunnleggende IT',
        fagkode: 'ITF13012',
        karakter: 'Bestått',
    },
    {
        fag: 'Webutvikling',
        fagkode: 'ITF10511',
        karakter: 'C',
    },
    {
        fag: 'Informasjonsarkitektur',
        fagkode: 'ITM30210',
        karakter: 'C',
    },
    {
        fag: 'Videoproduksjon',
        fagkode: 'ITM20817',
        karakter: 'D',
    },
    {
        fag: 'Designmetoder',
        fagkode: 'ITM11319',
        karakter: 'C',
    },
    {
        fag: 'Prosjektledelse',
        fagkode: 'ITF31314',
        karakter: 'B',
    },
    {
        fag: '3D-modellering',
        fagkode: 'ITM30519',
        karakter: 'B',
    },
    {
        fag: 'Skisser og prototyper',
        fagkode: 'ITM21619',
        karakter: 'A',
    },
    {
        fag: 'Innføring i datasikkerhet',
        fagkode: 'ITF15019',
        karakter: 'Bestått',
    },
    {
        fag: '3D-animasjon',
        fagkode: 'ITM32019',
        karakter: 'C',
    },
    {
        fag: 'Kommunikasjonsdesign',
        fagkode: 'ITM30719',
        karakter: 'B',
    },
    {
        fag: 'Design av virtuelle miljøer',
        fagkode: 'ITM31519',
        karakter: 'C',
    },
    {
        fag: 'Utvikling av interaktive nettsteder',
        fagkode: 'ITM30617',
        karakter: 'B',
    },
    {
        fag: 'Grafisk design',
        fagkode: 'ITM20718',
        karakter: 'C',
    },
    {
        fag: 'Digital markedsføring',
        fagkode: 'ITM31019',
        karakter: 'C',
    },
    {
        fag: 'Innføring i programmering',
        fagkode: 'ITF19019',
        karakter: 'A',
    },
    {
        fag: 'Bacheloroppgave',
        fagkode: 'ITF32012',
        karakter: 'C',
    },
]

export default function About() {
    let date = new Date();
    let todaysYear = date.getFullYear()
    let howOld = (todaysYear - 1998)
    return(
        <div className='about'>
            <section>
                <p className='title'>About me</p>
                <p>I just finished my bachlordegree in Digital Media and Design the summer of 2023 at Høgskolen i Østfold.
                    Im {howOld} years old and currenly based in Klepp Kommune. 
                    My hobbies are gaming, diving, lego and friends. .
                </p>
                <p>During my bachlordegree i learned that coding was something fun that peeked my interesst.
                    Which is the reason for why I want to work with frontend-development.
                    I look at my self as an loyal and friendly person, who are easy to get along with.
                    In my of my subjects during my degree I had to work in groups, 
                    which have given me experience in how to work/collaborate with my groupmembers. 
                </p>
                <p>
                    I currently dont have any former job experience to show for, 
                    but Im trying to build some small project to maintain my knowledge and learn new tricks.
                    Hope to hear from you!
                </p>
            </section>
            <section>
                <p className='title'>Skills</p>
                <p>
                    Throughout my degree i learned to code in Html, Css, Javascript, React and Sanity.

                </p>
            </section>
            <section>
                <p className='title'>Subjects and Grades</p>
                {karakter?.map((grades) => (
                    <li key={grades?.fag}>{grades?.fag}: {grades?.karakter}</li>
                ))}
            </section>
            <section>
                <p className='title'>Cv</p>
                
            </section>
        </div>
    )
}