/* Pages */
import Home from './pages/Home';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';

/* Components */
import Nav from './components/Nav';
import Footer from './components/Footer';

/* Css */
import './css/App.css';
import './css/Nav.css';
import './css/Home.css';

/* Extras */
import { Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <div id='body'>
      <header>
        <Nav />
      </header>
      <main>
        <Routes>
          <Route path='/'>
            <Route index element={<Home />} />
            <Route path='projects' element={<Projects />} />
            <Route path='about' element={<About />} />
            <Route path='contact' element={<Contact />} />
          </Route>
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}